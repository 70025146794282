html, body {
    scroll-behavior: smooth;
    overflow-x: hidden;
}

.newsCard {
    position: relative;
    display: flex;
    flex-direction: column;
    min-width: 100%;
    min-height: 100%;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: border-box;
    border: 1px solid rgba(0, 0, 0, 0.125);
    border-radius: 0.25rem;
}


.countryDetailCard {
    
        flex: 1 1 auto;
        min-height: 1px;
        padding: 1.25rem;
        max-height: 100%;
}


.card-img, .card-img-top, .card-img-bottom {
    flex-shrink: 0;
    width: 100px;
}


.timeText {
    color: #fff !important;
}

.apexcharts-series path {
   
   
    fill-opacity: 1;
    stroke-opacity: 1;
    stroke-linecap: round;
    stroke-width: 0;
    stroke-dasharray: 0;
    clip-path: url(#gridRectMask0m4cnfjmk);
}

#countryList {
    position: relative;
    display: block;
    padding: 0.75rem 1.25rem;
    margin-bottom: -1px;
    background-color: #7FBC41 !important;
    border: 1px solid whitesmoke;
    color: white;
}

#countryListMap {
    display: block;
    padding: 0.75rem 1.25rem;
    margin-left: 100px;
    background-color: #F4F7FB !important;
    border: 1px solid whitesmoke;
    width: 250px;
    border: 1px solid rgb(14, 10, 10);
}

.countryLinkMap {
    color: #000000 !important;
    font-size: medium;
    font-family: sans-serif;
}

#countryGroup{
    background-color: #7FBC41 !important;
    width: 250px;
}

#africanAgoraCards {
    border: 1px 0 0 1px solid #7FBC41 !important;
}

.countryLink{
    color: #fff !important;
    text-decoration: none !important;
    font-size: 15px;
}

#africanAgoraCradHeader{
    width: 30rem;
    background-color:#7FBC41;
    text-align:center;
    font-family:sans-serif;
    font-size:20px;
}

#africanAgoraCardText{
    color:black;
    font-family:sans-serif;
    font-size:16px;
}

.key {
    width:9rem;
    margin-left: 130px !important;
    margin-top: 50px;
}

.titl{
    color:#F15B26 !important;
}

.newsText {
    color:#F15B26 !important;
}

.header-bg {
    background-color: #374C5B;
}

#expression-cards{
    margin-bottom: 100px !important;
}

a {
    color: #7FBC41 !important;
    text-decoration: none;
    background-color: transparent;
    -webkit-text-decoration-skip: objects;
}


