.sidebar-page-container .sidebar .sidebar-post .post-inner .post{
	position: relative;
	padding: 0px 0px 0px 75px;
	padding-bottom: 10px;
	margin-bottom: 6px;
	border-bottom: 1px solid #e5e5e5;
}

.sidebar-page-container .sidebar .sidebar-post .post-inner .post:last-child{
	border-bottom: none;
}

.sidebar-page-container .sidebar .sidebar-post .post-inner .post .post-date{
    position: absolute;
    left: 0px;
    top: 4px;
    width: 54px;
    height: 54px;
    text-align: center;
    border-radius: 5px;

}
.sidebar-page-container .sidebar .sidebar-post .post-inner .post .post-date{
background: rgb(2,0,36);
background: -moz-linear-gradient(rgba(0,123,255,1) 100%);
background: -webkit-linear-gradient(rgba(0,123,255,1) 100%);
background: linear-gradient(rgba(0,123,255,1) 100%);
filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#020024",endColorstr="#7FBC41",GradientType=1);
}

.sidebar-page-container .sidebar .sidebar-post .post-inner .post .post-date p{
    display: block;
    font-size: 18px;
    font-weight: 500;
    color: #fff;
    text-align: center;
    margin:0px;
}

.sidebar-page-container .sidebar .sidebar-post .post-inner .post .post-date span{
    position: relative;
    display: block;
    font-size: 13px;
    line-height: 18px;
    text-transform: uppercase;
    color: #fff;
    margin:0px;
    padding:0px;
}

.sidebar-page-container .sidebar .sidebar-post .post-inner .post .file-box{
	position: relative;
	margin-bottom: 9px;
}

.sidebar-page-container .sidebar .sidebar-post .post-inner .post .file-box i{
    position: relative;
    display: inline-block;
    font-size: 14px;
    color: #666666 !important;
    margin-right: 10px;
}

.sidebar-page-container .sidebar .sidebar-post .post-inner .post .file-box p{
    position: relative;
    display: inline-block;
    margin-bottom:0px;
}

.sidebar-page-container .sidebar .sidebar-post .post-inner .post h5{
	position: relative;
	display: block;
	font-size: 18px;
	line-height: 28px;
	font-weight: 600;
	margin-bottom: 0px;
	color: #1d165c;
	margin:0px;
}

.sidebar-page-container .sidebar .sidebar-post .post-inner .post h5 a{
	display: inline-block;
	color: #1d165c;
}
.sidebar-page-container .sidebar .sidebar-post .post-inner .post h5 a:hover{
	color: #e61819;	
}
.carousel-inner-data{
  margin:0px auto;
  height:350px;
  overflow:hidden;
}
.carousel-inner-data ul{
  list-style:none;
  position:relative;
}
.carousel-inner-data li{
  height:auto;
}

.breadCrumb{
	color: #6c757d !important;
	text-decoration: none !important;
}



/* Vertical timeline CSS*/

ul.timeline {
  list-style-type: none;
  position: relative;
  overflow-y: scroll;
  max-height: 550px;
}
ul.timeline:before {
  content: ' ';
  background: #d4d9df;
  display: inline-block;
  position: absolute;
  left: 29px;
  width: 2px;
  height: 100%;
  z-index: 400;
}
ul.timeline > li {
  margin: 20px 0;
  padding-left: 20px;
}
ul.timeline > li:before {
  content: ' ';
  background: white;
  display: inline-block;
  position: absolute;
  border-radius: 50%;
  border: 3px solid #7FBC41;
  left: 20px;
  width: 20px;
  height: 20px;
  z-index: 400;
}